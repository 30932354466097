import { lazy } from 'react';
import { Route } from 'react-router-dom';

// 销售订单审批详情
const SellPigOrderDetail = lazy(() =>
  import(
    /* webpackChunkName: "SellPigOrderDetail" */ 'src/views/SellPigOrder/SellPigOrderDetail/index'
  )
);
const SellPigOrderRouter = {
  '/SellPigOrder/SellPigOrderDetail': SellPigOrderDetail
};

//  Shop 路由映射关系表
export default function LeavePurchaseApprovalRoute() {
  const keys = Object.keys(SellPigOrderRouter);
  const routeArr = keys.map(key => {
    const Components = SellPigOrderRouter[key];
    return <Route key={key} path={key} element={<Components />} />;
  });
  return routeArr;
}
